import React from 'react'
import './base.css'
import Container from './container'
import { Helmet } from "react-helmet"

import Navigation from './navigation'
import '../assets/main.css'
import heroStyles from "../components/hero.module.css";
import Jumbotron from "./css-components/jumbotron.js";
import Compare from './compare-components/dropdown-compare.js'
import CTA from './CTA/looking-financing.js'
import Loan from './compare-components/loan-svg.js'
import Breadcrumbs from './Breadcrumbs'

class Template extends React.Component {
  constructor(props){
      super(props);
  }
  
  render() {
    const { children } = this.props;

    return (
      <div style={{ background: '#fff' }}>
      {/* Taking this out ruins navbar color for some reason */}
      <Helmet>
          <meta name='viewport'  content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover' />
          <title>Working Capital</title>
          <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Navigation />

      {this.props.image ? 
      <div className="bg-gray-600 w-full">
        <div class="relative z-0 text-center rounded shadow-md flex card justify-center" style={{zIndex: "1", justify: "center",}}>
            <div class="h-full content-center" alt="Room Image" style={{height: "270px", background: 'url('+this.props.image+')', filter: 'blur(1px)', filter: 'brightness(30%)', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%'}}></div>
            <container class="absolute h-64">
            <div class="row h-100 content-center m-30">
            <div class="m-auto">
                <h1 className = "text-center text-white font-bold text-2xl md:text-4xl pt-16">
                    {this.props.title}
                </h1>
                {this.props.apply == "" ? <></> :
                 <a class="no-underline" href="https://working-capital.com/apply/"><p class="text-center cursor-pointer">
                    <button class="mb-4 cursor-pointer text-lg bg-green-600 text-white text-center font-semibold py-3 px-12 mr-10 border-transparent hover:bg-green-800 transition">{this.props.apply}</button>
                </p></a>}
            </div>
            </div>
            </container>
        </div>
    </div> : <></>}
    { this.props.location.pathname.split('/')[1]=='' ?
         <div class="w-min-full bg-gray-200 w-full h-25 pb-2 pt-2 text-center">
            <div class="flex flex-wrap justify-center">
                <div class="p-8">
                <img class="w-full h-20" src={require("../assets/img/network.svg")}></img>
                <p class="text-3xl m-2 text-green-600 font-bold">45+</p>
                 <p class="text-xl m-0">Available Lenders</p>
                </div>
                 
                <div class="p-8">
                    <img class="w-full h-20" src={require("../assets/img/effective.svg")}></img>
                    <p class="text-3xl m-2 text-green-600 font-bold">30+</p>
                    <p class="text-xl m-0">Lender Reviews</p>
                </div>
                <div class="p-8">
                    <img class="w-full h-20" src={require("../assets/img/laptop.svg")}></img>
                    <p class="text-3xl m-2 text-green-600 font-bold">60</p>
                    <p class="text-xl m-0">Second Applications</p>
                </div>

            </div>
         </div> :
        <Breadcrumbs url={this.props.location.pathname} title={''}/>
    }

    {/* <CTA /> */}


      <Container>
            <div className="container lg:flex">
                  {/* <div className="hidden xl:block lg:w-1/5 w-full lg:pt-24">
                    <CTA />
                  </div> */}
                  <div className="lg:w-3/4 w-full lg:px-4 pt-3">
                        {this.props.mainContent}
                        
                        
                        <Compare className="items-center" /> 
                       
                        {/* // <div className="shadow-lg">
                        // <a href=''>
                        //   <img className="m-auto max-w-xl border-4 border-gray-600" src="https://working-capital.com/img/cta-applying-is-free.png"></img>
                        // </a>
                        // </div> */}
                        
                        
                        
                   </div>
                   
                   {/*<p className="text-center text-xl">{this.props.heading}</p>*/}
                    <div className="flex content-center lg:w-1/5 w-full p-0 pt-16">
                            
                            
                          {this.props.sideContent}
                           
                           
                       
                    </div> 
                    
            </div>
           
      </Container>
      
    </div>
    )
  }
}

export default Template
