import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../assets/main.css";
import Card from "./css-components/top-image-card-article.js"

import styles from "./lender-preview.module.css";

export default ({ article }) => (
   console.log(JSON.stringify({article})),
  <Card node={article}  link={`/articles/${article.slug}`} />
);
