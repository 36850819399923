import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from '../pages/reviews.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import ArticlePreviewShort from '../components/article-preview-short'
import '../assets/main.css'
import SideContent from '../components/side-components/lender-side.js'
import { Link } from "gatsby"

class Article extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulArticle.edges')
    const lenders = get(this, 'props.data.allContentfulLender.edges')

    const { currentPage, numPages } = this.props.pageContext
    
    var url = this.props.location.pathname;
    var parsed = url.split("/");
    var x = parseInt(parsed[2]);
    this.x = parseInt(parsed[2]);
    console.log(x);
    console.log(this.x);

    if(isNaN(this.x)){
     this.x = 1;
	}

    const mainContent = (
        <div>
            {/*<h2 className="section-headline text-center">Recent Articles</h2>*/}
            <div className="">
            <div className="">

              {posts.map(({ node }) => {
                return (
                <div className="h-full">
                    <div className="sm:hidden h-full">
                        <ArticlePreviewShort key={node.slug} article={node}/>
                    </div>
                    <div className="hidden sm:block h-full">
                        <ArticlePreview key={node.slug} article={node}/>
                    </div>
                </div>
                )
              })}

            </div>
            <div className="justify-center text-center p-4">
                <div className="flex justify-center items-center">
                    
                    {Array.from({ length: numPages }, (_, i) => (
                    <div>
                           
                            {this.x == i+1 ?
                                    <div className="p-2">
                                    <Link className="" key={`pagination-number${i + 1}`} to={`/articles/${i === 0 ? "" : i + 1}`}>
                                        <div className=" font-bold text-2xl">{i + 1}</div>
                                    </Link>
                                    </div>
                                
                    :
                        <div className="p-2">
                        <Link className="no-underline" key={`pagination-number${i + 1}`} to={`/articles/${i === 0 ? "" : i + 1}`}>
                            <div className=" text-xl">{i + 1}</div>
                        </Link>
                        </div>
                    
                    }
                </div>
                ))}
                    
              </div>
          </div>
        </div>
        </div>
	);

    return (
      <Layout location={this.props.location} heading={"Related Lenders"} mainContent={mainContent} apply={""} title = {"Articles"} image={require("../assets/img/5.jpg")} sideContent={<SideContent articles={lenders} />}>
          <Helmet title={siteTitle} />
          
      </Layout>
    )
  }
}

export default Article

export const pageQuery = graphql`
  query ArticleQuery($skip: Int!, $limit: Int!){

   allContentfulLender(
    sort: { fields: [title], order: DESC } 
    limit: $limit
    skip: $skip
    ){
      edges {
        node {
        tagList {
            title
          }
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }

   allContentfulArticle(sort: { fields: [title], order: DESC }
   limit: $limit
   skip: $skip){
      edges {
        node {
          title
          description {
            description
          }
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          image {
            file {
                url
            }
}
        }
      }
    }
  }`