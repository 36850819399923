import React from "react";
import '../../assets/main.css'

class owner extends React.Component{
    constructor(props) {
        super(props);
    }

	render(){
		return(
			<div className="hidden xl:block sticky inset-x-0 top-0 pl-32 text-center p-2 pt-8 w-64 h-0">
				<p className="font-bold text-xl pb-1">Looking for Business Financing?</p>
				<p className="text-lg text-green-600 hover:text-green-800 pt-0 mb-0 mt-0"><a href="https://working-capital.com/apply/" className="no-underline">Read our Lender Reviews</a></p>
				<p className="text-lg py-4 mt-0 mb-0">- or -</p>
				<a href="https://working-capital.com/apply/" className="no-underline"><button class="m-1 border-none cursor-pointer bg-green-600 text-white font-semibold py-3 px-12 mr-2 mb-1 inline-flex justify-center border-transparent hover:bg-green-800 transition"> Apply for Financing Now </button> </a>
			</div>
		);
	}
}

export default owner