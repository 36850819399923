import React from "react";
import Img from "gatsby-image";
import "../../assets/main.css";
import { Link } from "gatsby";

export default ({ node, link}) => (
<div class="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-8 bg-white">
  
  {node.image.file.url ? 
  <img src={node.image.file.url}></img> : <></>}


  <div class="px-4 py-4 text-left">
    <div class="font-bold text-m mb-1 text-black text-black hover:text-gray-600"><a href={link} class="no-underline">{node.title}</a></div>
    <p class="text-black p-0 pb-1 mt-0" style={{fontSize: "14px"}}>{node.publishDate}</p>
    <p class="text-gray-600 p-0 m-0" style={{fontSize: "12px"}}>
     {node.description.description}
    </p>
  </div>
  {/*<div class="px-6 py-0">
    <button className="text-center py-3 px-6 m-3 bg-purple-800 hover:bg-purple-light text-white font-bold rounded mt-1 mb-2 text-center w-full">
                        <Link to={link} style={{textDecoration: "none"}}>Read more</Link>
                    </button>
  </div>*/}
</div>

);