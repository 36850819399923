import React from "react";
import Img from "gatsby-image";
import "../../assets/main.css";
import { Link } from "gatsby";

export default ({ node, link}) => (
<div class="max-w-xl rounded overflow-hidden items-center content-center shadow  my-4 bg-white" >
  <a href={link}><div className="p-4" >
  <img src={node.image.file.url}
 ></img>
  </div></a>

  <div class="px-1 py-1 text-center">

    
    <p class="text-gray-600 p-0 m-0" style={{fontSize: "12px"}}>
     <div class="tags w-full text-center box-border ">
            {node.tagList ? (
              node.tagList
                .slice(0, 3)
                .map((title) => (
                  <div className="inline-block rounded bg-gray-200 rounded-sm mr-1 mb-1 p-1 hover:bg-gray-300 text-xs" style={{fontSize:"10px"}}>
                    {title.title}
                  </div>
                ))
            ) : (
              <div className="line-clamp px-2">{node.description.description}</div>
            )}
          </div>
    </p>
  </div>
  <div class="px-2 py-0">
  <Link to={link} style={{textDecoration: "none"}}>
    <button className="cursor-pointer text-center bg-green-600 hover:bg-green-800 text-white border-none mt-1 mb-2 p-2 w-full" style={{fontSize: 10}}>
                       Get Funded
                    </button>
    </Link>
  </div>
</div>

);