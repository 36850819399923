import React from "react";
import Img from "gatsby-image";
import "../../assets/main.css";
import { Link } from "gatsby";

export default ({ node, link }) => (
  <div class="w-full rounded overflow-hidden shadow-lg grid-flow-col bg-white relative mb-5">
    <div class="font-bold text-xl p-4 bg-gray-200 text-black hover:text-gray-600">
      <Link to={link} style={{ textDecoration: "none" }}>
        {node.title}
      </Link>
    </div>
    <div className="lg:flex">
      <img
        alt="Placeholder"
        class="block h-auto w-full"
        style={{
          flexShrink: 1,
          maxWidth: "100%",
          margin: "auto",
          height: "auto",
          padding: 10,
          width: 200,
        }}
        src={node.image.file.url}
      ></img>
      <div class="px-6 py-4">
        <p class="text-gray-700 text-base">{node.description.description}</p>
      </div>
    </div>
  </div>
);
