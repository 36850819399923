import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import ArticlePreview from '../lender-preview-short'
import '../../assets/main.css'

export default class LenderSide extends React.Component {

	render(){
		// const articles = get(this, 'props.data.allContentfulArticle.edges');

        return(
        <div>
         
        <div className="text-center">
           
            <div className="grid grid-cols-1 m-1">
            <a href=''>
                  <img className="max-w-full" src="https://working-capital.com/img/cta-business-owner.png"></img>
                </a>
                <p className="text-center text-2xl">Related Lenders</p>
              {this.props.articles.map(({ node }) => {
                return (
                  <div key={node.slug}>
                    <ArticlePreview article={node} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        )
	}
}
